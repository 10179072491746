@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://use.typekit.net/stc1fan.css');

:root {
  --athens-dark-navy: #25394a;
  --athens-light-navy: #415569;
  --athens-dark-blue: #1d63ae;
  --athens-blue: #0072da;
  --athens-light-blue: #edf4f6;
  --athens-light-grey: #efefef;
  --athens-green: #00ffb1;
  --athens-white: #ffffff;
  --border-radius: 5px;
  --default-transition: all 200ms ease-in-out;
  --tabs-height: 60px;
  --padding-x: 20px;
  --padding-x-sm: 30px;
  --padding-x-lg: 60px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  font-size: 16px;
}

#quote-app {
  background-color: var(--athens-light-grey);
  overflow: hidden;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

header {
  min-height: 60px;
}

header .logo {
  height: 24px;
  max-width: 100%;
}

header button {
  background-color: var(--athens-light-grey);
  height: 35px;
  font-weight: bold;
  border: none;
  transition: var(--default-transition);
}

.padding-x {
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
}

.pointer {
  cursor: pointer;
}

.products-tabs {
  overflow-x: auto;
  position: relative;
  z-index: 1;
}

.products-tabs::before {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--tabs-height);
  right: 0;
  top: 0;
  background-color: var(--athens-light-blue);
  z-index: 0;
}

.products-tabs .nav-item {
  z-index: 100;
}

.products-tabs .nav-item .nav-link {
  color: var(--athens-dark-navy);
  width: 148px;
  height: var(--tabs-height);
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 0 13px;
  margin-bottom: 18px;
  background-color: var(--athens-light-blue);
  position: relative;
}

.products-tabs .nav-item .nav-link.active {
  color: var(--athens-white);
  background-color: var(--athens-dark-blue);
  position: relative;
}

.products-tabs .nav-item:last-child .nav-link::after {
  content: '';
  position: absolute;
  width: var(--padding-x);
  height: var(--tabs-height);
  left: 100%;
  top: 0;
  background-color: var(--athens-light-blue);
  z-index: 0;
}

.products-tabs .nav-item .nav-link.active::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 74px solid transparent;
  border-right: 74px solid transparent;
  border-top: 18px solid var(--athens-dark-blue);
}

ul.products-tabs {
  border-bottom: none;
}

ul .product-item {
  /* min-width: 175px; */
  height: 55px;
  background-color: var(--athens-light-grey);
  color: #a2a2a2;
  display: block;
  text-align: center;
  border: none;
  font-weight: bold;
}

ul .product-item.selected {
  background-color: var(--athens-dark-blue);
  color: var(--athens-white);
  transition: var(--default-transition);
}

#selections-section .form-control,
#selections-section .form-select {
  height: 46px;
  width: 164px;
  border-radius: var(--border-radius);
}

#selections-section h3 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
}

.card-border {
  border: 1px solid var(--athens-blue);
  overflow: hidden;
}

.card-border.primary-border {
  border: 1px solid var(--athens-blue);
}

.option-card.active {
  border-width: 4px;
}

.option-card .output {
  min-height: 64px;
}

.option-card.disabled {
  opacity: 0.5;
}

.option-card.disabled .pointer {
  cursor: not-allowed !important;
}

.option-card h3 {
  font-family: brandon-grotesque, sans-serif;
}

#option-items .container:last-child {
  border-bottom: none !important;
}

/* ******************* Background Color ******************* */
.athens-bg-navy {
  background-color: var(--athens-dark-navy);
}

.athens-bg-dark-grey {
  background-color: rgba(65, 85, 105, 0.3);
}

.athens-bg-white {
  background-color: var(--athens-white);
}

.athens-bg-grey {
  background-color: var(--athens-light-grey);
}

.athens-bg-dark-blue {
  background-color: var(--athens-dark-blue);
}

.athens-bg-light-blue {
  background-color: var(--athens-light-blue);
}

.athens-bg-green {
  background-color: var(--athens-green);
}

.d-h {
  font-size: 1.75rem;
  font-weight: 700 !important;
  letter-spacing: -1px;
}

/* ******************* Buttons ******************* */
/* Milan Default Button */
.athens-btn {
  border: none;
  transition: var(--default-transition);
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  display: inline-block;
  width: auto;
  line-height: 1.875rem;
  cursor: pointer;
  text-decoration: none;
}

.athens-btn:disabled:hover,
.athens-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.athens-primary-btn {
  background-color: var(--athens-dark-blue);
  color: var(--athens-white);
}

.athens-navy-btn {
  background-color: var(--athens-dark-navy);
  color: var(--athens-white);
}

/* Milan Grey Button */
.athens-grey-btn {
  background-color: var(--athens-grey);
  color: var(--athens-dark-navy);
}

.athens-grey-btn:hover {
  background-color: rgba(194, 194, 194, 0.5);
  color: var(--athens-dark-navy);
}

/* Milan Green Button */
.athens-green-btn {
  background-color: var(--athens-green);
  color: var(--athens-dark-navy);
}

.clear-option-btn {
  width: 160px !important;
  height: 45px !important;
}

.quote-action-btn {
  color: var(--athens-dark-navy);
}

.quote-action-btn.active {
  background-color: var(--athens-dark-blue);
  color: var(--athens-white);
}

/* ******************* Text Colors ******************* */
.athens-text-navy {
  color: var(--athens-dark-navy) !important;
}

.athens-text-light-navy {
  color: var(--athens-light-navy) !important;
}

.athens-text-primary {
  color: var(--athens-dark-blue) !important;
}

.athens-text-blue {
  color: var(--athens-blue) !important;
}

.athens-text-white {
  color: var(--athens-white) !important;
}

/* Font Weight */
.fw-500 {
  font-weight: 500 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/* ******************* Custom Checkbox - Specific for Option Tiles ******************* */
.checkbox-container {
  width: 100%;
  font-size: 0.9rem;
}

.checkbox-container .checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox-container .checkbox {
  position: relative;
  background-color: var(--athens-white);
  width: 18px;
  height: 18px;
  border: 1px solid var(--athens-dark-blue);
  border-radius: 3px;
}

.checkbox-container .checkbox.checked {
  background-color: var(--athens-dark-blue);
}

.checkbox-container .checkbox.checked span {
  position: absolute;
  top: -4px;
  left: 2px;
  display: block;
  color: #fff;
}

/* ******************* Custom Checkbox ******************* */
.custom-checkbox label {
  cursor: pointer;
  display: block;
  padding-left: 28px;
  margin-bottom: 0px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  font-size: 0.9rem;
}

.custom-checkbox label input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.custom-checkbox .checkmark {
  background-color: var(--athens-off-white);
  height: 18px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 18px;
  border-radius: 3px;
}

.custom-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  border: solid var(--athens-dark-navy);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 7px;
  top: 4px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.print-display {
  display: none;
}

.warning-border {
  border: 2px solid var(--athens-blue);
}

/* .currency-input {
  height: 28px;
  max-width: 120px;
  font-size: 14px;
  transition: var(--default-transition);
}
.currency-input:focus {
  height: 40px;
  font-size: 16px;
}
.border-blue {
  border: 1px solid var(--athens-blue);
} */

select#single_discount {
  cursor: pointer;
}

select#single_discount:disabled {
  cursor: not-allowed;
  background-color: var(--athens-light-grey) !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.question-mark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--athens-dark-blue);
  border-radius: 50%;
  color: var(--athens-dark-blue);
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  width: 160px;
  height: auto;
  padding: 12px;
  background-color: white;
  color: var(--athens-dark-blue);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e5e7eb;
}

.tooltip-text.visible {
  visibility: visible;
}

.tooltip-text::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent #e5e7eb transparent transparent;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

@media (min-width: 576px) {
  header .logo {
    height: 20px;
  }

  .padding-x {
    padding-left: var(--padding-x-sm);
    padding-right: var(--padding-x-sm);
  }

  ul .grafts-item {
    min-width: 175px;
  }
}

@media (min-width: 992px) {
  header {
    height: 89px;
  }

  .padding-x {
    padding-left: var(--padding-x-lg);
    padding-right: var(--padding-x-lg);
  }
}

@media (min-width: 1200px) {
  header .logo {
    height: 24px;
  }
}

@media print {
  @page {
    size: landscape;
  }

  #print-container {
    margin-left: 30px;
  }

  .col-print {
    width: 33% !important;
  }

  .print-hide {
    display: none !important;
  }

  .print-display {
    display: block;
  }
}
